// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-book-jsx": () => import("./../src/templates/book.jsx" /* webpackChunkName: "component---src-templates-book-jsx" */),
  "component---src-templates-page-jsx": () => import("./../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-serie-jsx": () => import("./../src/templates/serie.jsx" /* webpackChunkName: "component---src-templates-serie-jsx" */)
}

